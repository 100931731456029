import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import config from '../../api/config'
import { useUserRole } from 'hooks'
import Modal from '../Elements/Modal'
import { authenticationService} from '../../api'
import FeedbackForm from '../Footer/FeedbackForm'
import { UserContext,useOrgId } from '../../hooks'

const ProfileMenu = () => {
	const [isOpen, setIsOpen] = useState(false)
	const { currentUser } = useContext(UserContext)
	const orgId = useOrgId()
	const currentRole = useUserRole()
	const { currentOrganizations} = useContext(UserContext)
	const currentOrganization = currentOrganizations[orgId]

	var name = currentUser.public_name
	var initials = Array.prototype.map.call(name.split(' '), function(x){ return x.substring(0,1).toUpperCase();}).join('');

	const ToggleProfileMenu = (e) => {
		var element = document.getElementById('profile-list')
		if (element.classList.contains('hidden')) {
			element.classList.remove('hidden')
			element.classList.add('shown')
		} else {
			element.classList.add('hidden')
			element.classList.remove('shown')
		}
	}

	const handleLogout = () => {
		authenticationService
			.logout()
			.then((response) => {
				window.location.assign(`${config.home}/login`)
			})
			.catch((error) => {
				console.error('Logout failed', error)
			})
	}

	return (
		<>  
			<div className='ml-10'>{currentUser?.profile?.profile_pic ? (<img
					 className='MenuProfileImage'
					 src={currentUser?.profile?.profile_pic}
					 />) : (<div className='MenuProfileImage'>{initials}</div>)}</div>
			<div className='right cursor center-mobile my-10' onClick={ToggleProfileMenu}>
		
				<input className='profile-menu__checkbox' type='checkbox' id='profile2' />
				<span className='profile-menu__name subheading is-dark'>{currentUser?.public_name}
					<br></br>

				{currentOrganization && (
					<small style={{ textTransform: 'capitalize', marginRight: '5px'}}>
						<strong>
							{currentRole === 'CASE_MANAGER' ? 'Project Manager' : currentRole.replace('_', ' ').toLowerCase()}
						</strong>
					</small> 
				)}
				
				</span>
				<i className='fas fa-angle-down text-icon' />

				<ul id='profile-list' className='profile-menu__list hidden subheading is-dark'>
					<Link to={`/profile/${currentUser?.cognito_id}`}>
						<li className='profile-menu__item cursor'>Profile</li>
					</Link>
					{currentUser && currentUser.role === 'SUPER_ADMIN' && (
						<Link to='/superadmin-settings'>
							<li className='profile-menu__item cursor'>Super Admin Settings</li>
						</Link>
					)}
						<Link to='/organizations'>
							<li className='profile-menu__item cursor'>Organizations</li>
						</Link>
					{/*<Link to={`/tickets/${currentUser?.cognito_id}`}>
						<li className='profile-menu__item cursor'>Tickets</li>
					</Link>*/}
					<li
						className='profile-menu__item cursor'
						onClick={() => {
							setIsOpen(true)
						}}
					>
						Leave Feedback
					</li>
					<li className='profile-menu__item cursor' onClick={handleLogout}>
						Logout
					</li>
				</ul>
			</div>
 
			<Modal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false)
				}}
			>
				<FeedbackForm
					onClose={() => {
						setIsOpen(false)
					}}
				/>
			</Modal>
		</>
	)
}
 
export default ProfileMenu
